<template>
    <ion-app>
        <ion-router-outlet/>
    </ion-app>
</template>

<script lang="ts">
import {
    IonApp,
    IonRouterOutlet
} from "@ionic/vue";
import {
    computed,
    defineComponent
} from "vue";

export default defineComponent({
    name: "App",
    components: {
        IonApp,
        IonRouterOutlet
    },
    data: () => ({
        darkMode: false
    }),
    provide() {
        return {
            $darkMode: computed(() => this.darkMode)
        };
    },
    created() {
        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

        this.darkMode = prefersDark.matches;

        prefersDark.addEventListener(
            "change",
            (mediaQuery: MediaQueryListEvent) => {
                this.darkMode = mediaQuery.matches;
            }
        );
    }
});
</script>
