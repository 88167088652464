import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";

import {IonicVue} from "@ionic/vue";
import {createPinia} from "pinia";

import axios from "./plugins/axios";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

import "./theme/main.scss";

const pinia = createPinia();

const app = createApp(App)
    .use(IonicVue, {
        mode: "md"
    })
    .use(pinia)
    .use(axios, {
        apiBaseURL: process.env.VUE_APP_API_URL
    })
    .use(router);

app.provide("version", "v1.0.0");

router
    .isReady()
    .then(() => {
        app.mount("#app");
    });
