import axios, {AxiosInstance} from "axios";
import {useSession} from "@/stores/session";
import {App} from "vue";
import {useRouter} from "vue-router";
import {AuthToken} from "@/models/AuthToken";

function logout() {
    const session = useSession();
    const router = useRouter();

    session.logout();
    router.push("/");
}

async function refreshTokenIfNeeded(apiBaseUrl: string) {
    const session = useSession();

    // Handle token expiration.
    if (session.isTokenExpired) {
        logout();

        throw new axios.Cancel("Authorisation token expired.");
    }

    // Check if we should refresh token.
    if (!session.shouldRefreshToken) {
        return;
    }

    const refreshAxios = axios.create({
        baseURL: apiBaseUrl,
        responseType: 'json',
        headers: {
            common: {
                "Accept": "application/json",
                "Authorization": "Bearer " + session.token
            }
        }
    });

    try {
        const response = await refreshAxios.post<AuthToken>("/auth/refresh", {responseType: "json"});

        if (response.status !== 200) {
            logout();
            return;
        }

        session.login(response.data);
    } catch (ex) {
        logout();
    }
}

export default {
    install(app: App, options: any) {
        const session = useSession();

        const apiAxios = axios.create({
            baseURL: options.apiBaseURL,
            responseType: 'json',
            headers: {
                common: {
                    "Accept": "application/json"
                }
            }
        });

        apiAxios.interceptors.request.use(
            async (config) => {
                if (session.isLoggedIn) {
                    // Handle token expiration.
                    await refreshTokenIfNeeded(options.apiBaseURL);

                    if (config.headers) {
                        // Set authorization header.
                        config.headers.Authorization = "Bearer " + session.token;
                    }
                }

                return config;
            },
            (error) => Promise.reject(error)
        );

        apiAxios.interceptors.response.use(
            undefined,
            (error) => {
                if (session.isLoggedIn) {
                    if (typeof error === "object" && error.response) {
                        if (error.response.status === 401) {
                            logout();
                            return;
                        }
                    }
                }
            }
        );

        app.provide<AxiosInstance>('api', apiAxios);
        app.config.globalProperties.$api = apiAxios;

        if (options.pinia) {
            options.pinia.use(() => ({$api: apiAxios}));
        }
    }
};
