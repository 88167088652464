import {
    createRouter,
    createWebHistory
} from "@ionic/vue-router";
import {RouteRecordRaw} from "vue-router";
import {useSession} from "@/stores/session";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/tab/scanning"
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/LoginView.vue")
    },
    {
        path: "/tab",
        component: () => import("@/views/MainTabs.vue"),
        redirect: "/tab/scanning",
        children: [
            {
                path: "scanning",
                name: "tab-scanning",
                component: () => import("@/views/package/PackageScanView.vue")
            },
            {
                path: "packages",
                name: "tab-packages",
                component: () => import("@/views/package/PackageListView.vue")
            },
            {
                path: "locations",
                name: "tab-locations",
                component: () => import("@/views/location/LocationListView.vue")
            }
        ]
    },
    {
        path: "/packages/:id",
        name: "package-details",
        component: () => import("@/views/package/PackageDetailsView.vue"),
        props: true
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to) => {
    const session = useSession();

    if (to.name === "login" && session.isLoggedIn) {
        return "/";
    }

    if (to.name !== "login" && !session.isLoggedIn) {
        return {name: "login"};
    }
});

export default router;
