import {defineStore} from "pinia";
import {RemovableRef, useSessionStorage} from "@vueuse/core";
import {AuthToken} from "@/models/AuthToken";
import dayjs from 'dayjs';

type SessionState = {
    data: RemovableRef<SessionContents>,
    tokenRefreshThreshold: number
};

type SessionContents = {
    token?: string,
    expiresAt?: string,
    location?: string
};

export const useSession = defineStore({
    id: "session",
    state: (): SessionState => ({
        data: useSessionStorage("session", {} as SessionContents),
        tokenRefreshThreshold: 15
    }),
    getters: {
        token(state): string | undefined {
            return state.data.token;
        },
        isLoggedIn: (state): boolean => !!state.data.token,
        isTokenExpired(state): boolean {
            if (this.isLoggedIn === false) {
                return true;
            }

            if (!state.data.expiresAt) {
                return false;
            }

            const now = dayjs();
            const expiresAt = dayjs(state.data.expiresAt);

            return expiresAt.isBefore(now);
        },
        shouldRefreshToken(state): boolean {
            if (this.isTokenExpired) {
                return false;
            }

            if (!state.data.expiresAt) {
                return false;
            }

            const now = dayjs();
            const expiresAt = dayjs(state.data.expiresAt).subtract(state.tokenRefreshThreshold, 'minutes');

            return expiresAt.isBefore(now);
        }
    },
    actions: {
        login(data: AuthToken): void {
            this.data.token = data.token;
            this.data.expiresAt = data.expiresAt;
        },
        logout(): void {
            this.data.token = undefined;
            this.data.expiresAt = undefined;
        }
    }
});
